/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnvironmentConfig } from '@unified-platform-ui/shared-core';

declare const WebConfig: any;

export const DefaultConfig = {} as EnvironmentConfig;

export class ExternalConfigService {
  private static _config: EnvironmentConfig;

  static get Config(): EnvironmentConfig {
    return this._config || DefaultConfig;
  }

  private static _createConfig(config: any): EnvironmentConfig {
    const _config = { ...DefaultConfig, ...(<EnvironmentConfig>config) };
    ExternalConfigService._config = _config;
    return _config;
  }

  static loadAppConfig(): void {
    this._createConfig(WebConfig);
  }
}
